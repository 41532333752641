/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import "./assets/sass/typo.scss";
// @import "./assets/sass/searchbar";
// @import "./assets/sass/button";
// @import "./assets/sass/utility";
@import "./assets/sass/main";
// global.scss
@import "~swiper/scss";
@import "~swiper/scss/pagination";
@import "~swiper/scss/scrollbar";
@import "~@ionic/angular/css/ionic-swiper";
@import "swiper/scss/navigation";
@import "swiper/css/bundle";


.swiper-button-prev {
  color: white;
  background-image: none;
}

// .swiper .swiper-slide {
//   margin-top:5px;


// }
.swiper-button-next {
  color: white;
  background-image: none;
}

// .swiper-slide {
//   overflow-y: scroll;    
//   display: block;    
// }

.margin-auto {
  margin: auto;
}

.confirmationModal {
  --width: 300px;
  --height: 328px;
  --border-radius: 20px;
}

.farmer-message-modal {
  --backdrop-opacity: .30 !important;
}

.update-modal {
  --width: 310px;
  --height: 340px;
  --border-radius: 18px;
}

.payment-screen {
  --width: 400px;
  --height: 400px;
  --border-radius: 14px;
}

.slider-pager {
  .swiper-pagination {
    bottom: 0px;
  }

  .swiper-pagination-bullet {
    height: 4px;
    border-radius: 30px;
    opacity: 34%;
    background: var(--color-mallard-step-400);
    width: 4px;
  }
}

.contactUsModal {
  --max-height: 24%;

  &::part(content) {
    position: absolute;
    bottom: 0%;
  }
}

.change-address {
  --max-height: 45%;

  &::part(content) {
    position: absolute;
    bottom: 0%;
  }
}

.quantity-modal::part(handle) {
  width: 33%;
  background: #9ac652;
}


.quantity-modal::part(content) {
  --border-radius: 27px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.securityMessageModal::part(content) {
  --border-radius: 27px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.lowBalancePopup::part(content) {
  --border-radius: 20px;
}

.lowBalancePopup::part(handle) {
  width: 33%;
  background: transparent;
}

.product-test-modal.modal-interactive::part(handle) {
  width: 33%;
  background: #9ac652;
}

.product-test-modal::part(content) {
  --border-radius: 27px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.farmer-filter::part(content) {
  --border-radius: 27px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.payment-modal.modal-interactive::part(handle) {
  width: 33%;
  background: #9ac652;
}

.save-card-class.modal-interactive::part(handle) {
  width: 17%;
  height: 3px;
  background: #9ac652;
}

.payment-modal::part(content) {
  --border-radius: 27px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.add-delivery-date {
  --max-height: 50%;

  &::part(content) {
    position: absolute;
    bottom: 0%;
  }
}

ion-searchbar {
  .searchbar-input-container {
    input {
      font-size: var(--font-size-h3);
      font-family: "Lato-Medium" !important;
      color: var(--color-dark-step-50);
    }
  }
}

ion-page {
  background: white;
}

:host ::ng-deep.button-solid {
  --box-shadow: none !important;
}

.back-icon {
  width: 35px;
  height: 36px;
  background: #cdc7d3a1;
  border-radius: 50%;
  color: white;
  min-width: auto;
  --padding-start: 0px;
  --padding-end: 0px;
}

// .arrow-back {
//   background-image:url(assets/icon/arrow.svg);
//   background-repeat: no-repeat;
//   width: 100%;
// }
// .arrow-white{
//   .arrow-back {
//     background-image:url(assets/icon/arrow-white.svg)!important;
//   }
// }
#main-content {
  background-color: #fff !important;
}

.home_margin {
  margin-inline-start: 17px;
  margin-inline-end: 20px;
}

.content-margin-main {
  margin-right: 30px;
  margin-left: 30px;
}

/* header footer CSS */
.top-bottom-margin {
  --padding-bottom: var(--ion-padding, 68px);
  --padding-top: var(--ion-padding, 68px);
  --padding-end: var(--ion-padding, 0px);
  --padding-start: var(--ion-padding, 0px);
  margin-bottom: 68px;
}

.bottom-margin {
  bottom: 68px;
  --padding-bottom: var(--ion-padding, 0px);
  --padding-top: var(--ion-padding, 68px);
  --padding-end: var(--ion-padding, 0px);
  --padding-start: var(--ion-padding, 0px);
}

.top-margin {
  --padding-bottom: var(--ion-padding, 0px);
  --padding-top: var(--ion-padding, 68px);
  --padding-end: var(--ion-padding, 0px);
  --padding-start: var(--ion-padding, 0px);
  margin-bottom: 68px;
}

ion-searchbar ion-icon {
  right: 18px !important;
  left: auto !important;
}

.searchbar-input.sc-ion-searchbar-md {
  padding-inline-start: 26px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: var(--font-size-h3);
  line-height: 0 !important;
  height: 47px;
  box-shadow: rgb(0 0 0 / 8%) 4px 2px 5px 0px;
}

.searchbar-search-icon {
  background-image: url("./assets/icon/homepage/search.svg") !important;
  margin-top: 3px;
  width: 17px !important;
  height: 17px !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
  color: #ffffff00 !important;
}

.searchbar-clear-button.sc-ion-searchbar-md {
  display: none !important;
}

ion-datetime .calendar-body .calendar-month .calendar-month-grid {
  padding-left: unset;
  padding-right: unset;
  padding-inline: 0px !important;
}

ion-datetime .calendar-day::after {
  border-radius: 32px;
  padding: 4px;
  left: 50%;
  top: 50%;
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  transform: translate(-50%, -50%);
  content: " ";
  z-index: -1;
}

.product-swiper .swiper-pagination-bullet-active {
  background: rgb(255, 177, 0);
}

.product-swiper .swiper-horizontal>.swiper-pagination-bullets,
.product-swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.product-swiper .swiper-pagination-custom,
.product-swiper .swiper-pagination-fraction {
  bottom: 0px;
  background: white;
}

.product-swiper .swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 13px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 4px));
  border-radius: 2px;
}

.product-details .swiper-pagination-bullet-active {
  background: rgb(255, 177, 0);
}

.product-details .swiper-horizontal>.swiper-pagination-bullets,
.product-details .swiper-pagination-bullets.swiper-pagination-horizontal,
.product-details .swiper-pagination-custom,
.product-details .swiper-pagination-fraction {
  bottom: 0px;
  background: white;
}

.product-details .swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 11px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 3px));
  border-radius: 2px;
}

.show-plan {

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px !important;
  }
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      color: transparent;
      background: url(../src/assets/icon/product/open.svg) no-repeat;
    }
  }
}

ion-accordion.accordion-expanding>[slot=header] .ion-accordion-toggle-icon,
ion-accordion.accordion-expanded>[slot=header] .ion-accordion-toggle-icon {
  background: url(../src/assets/icon/product/close.svg) no-repeat;
  transform: none;
}

.inputFields .native-input.sc-ion-input-md {
  margin-left: 40px;
  caret-color: #9ac652;
  border-radius: 1.5px;
  padding-inline-start: 1px;
}

.ng-otp-input-wrapper {
  display: flex;
  justify-content: space-around;

}

.otp-input {
  width: 65px !important;
  font-size: 24px !important;
  caret-color: #9ac652;
  height: 67px !important;
  border-radius: 10px !important;
  border-color: rgba(154, 198, 82, 70%) !important;
  color: var(--color-dark-step-50) !important;
  background: white;
}

.signup-journey .swiper-horizontal>.swiper-pagination-bullets,
.signup-journey .swiper-pagination-bullets.swiper-pagination-horizontal,
.signup-journey .swiper-pagination-custom,
.signup-journey .swiper-pagination-fraction {
  background: transparent;
  margin-top: 2px;
  // padding-bottom: 39px;
  position: relative;
  margin-left: var(--spacing-md);
  text-align: left;
  z-index: -2;
}

.signup-journey .swiper-pagination-bullet {
  background: rgba(184, 215, 133, 70%);
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
}

.signup-journey .swiper-pagination-bullet-active {
  background: rgb(154, 198, 82);
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 25px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  border-radius: 4px;
}

.promos .swiper-horizontal>.swiper-pagination-bullets,
.promos .swiper-pagination-bullets.swiper-pagination-horizontal,
.promos .swiper-pagination-custom,
.promos .swiper-pagination-fraction {
  background: transparent;
  display: flex;
  text-align: left;
  bottom: 0;
}

.promos .swiper-pagination-bullet-active {
  background: rgb(154, 198, 82);
  border-radius: 4px;
}

.promos .swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 34%));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 7px));
  border-radius: 0px;
  margin: -2px !important;
}

.hide-footer {
  app-footer {
    display: none;
  }

  .whats_app {
    display: none;
  }
}

.hide-header-footer {
  app-header {
    display: none;
  }

  .top-bottom-margin {
    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
  }

  app-footer {
    display: none;
  }

  .whats_app {
    display: none;
  }
}

.payment_success {
  color: rgb(154, 198, 82);
  font-size: var(--font-size-extra-h1);
  font-family: "Lato-Semi-Bold";
  white-space: pre-wrap;
}

.payment_error {
  color: rgb(195, 40, 40);
  font-size: var(--font-size-extra-h1);
  font-family: "Lato-Semi-Bold";
  white-space: pre-wrap;
}

.payment_pending {
  color: #eba562;
  font-size: var(--font-size-extra-h1);
  font-family: "Lato-Semi-Bold";
  white-space: pre-wrap;
}

.payment-screen::part(content) {
  background: transparent !important;
}

.toast-custom {
  top: -70px;
}

@media (min-width: 475px) {
  .home_margin {
    margin-inline-start: 34px;
    margin-inline-end: 40px;
  }

  .product_details,
  .my-custom-class,
  .referModal,
  .payment-modal,
  .login-alert-class,
  .farmer_certificate-details {
    --height: 100%;
    --width: 100%;
  }

  .contactUsModal {
    --width: 100%;
    --max-height: 18%;

  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    top: 21px;
  }

  .searchbar-input.sc-ion-searchbar-md {
    padding-inline-start: 26px !important;
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: var(--font-size-h1);
  }

  .confirmationModal {
    --width: 600px;
    --height: 628px;
    --border-radius: 30px;
  }

  .otp-input {
    width: 100% !important;
    font-size: 40px !important;
    height: 120px !important;
  }

  .update-modal {
    --width: 610px;
    --height: 640px;
    --border-radius: 28px;
  }

  .payment-screen {
    --width: 700px;
    --height: 700px;
    --border-radius: 24px;
  }

  .content-margin-main {
    margin: 30px 60px;
    // font-size: 34px;
  }

  .searchbar-input-container.sc-ion-searchbar-md {
    width: 99%;
  }

  .top-bottom-margin {
    --padding-bottom: var(--ion-padding, 86px);
    --padding-top: var(--ion-padding, 86px);
    --padding-end: var(--ion-padding, 0px);
    --padding-start: var(--ion-padding, 0px);
  }

  .bottom-margin {
    bottom: 86px;
    --padding-bottom: var(--ion-padding, 0px);
    --padding-top: var(--ion-padding, 86px);
    --padding-end: var(--ion-padding, 0px);
    --padding-start: var(--ion-padding, 0px);
  }

  .top-margin,
  .desktop-top-bottom-margin {
    --padding-bottom: var(--ion-padding, 0px);
    --padding-top: var(--ion-padding, 86px);
    --padding-end: var(--ion-padding, 0px);
    --padding-start: var(--ion-padding, 0px);
  }

  .large-button {
    height: 60px;
    font-size: var(--font-size-h1);
  }
}

.delete-card {
  --width: 300px !important;
  --min-width: 300px !important;
  --height: 266px;

  .alert-wrapper.sc-ion-alert-md {
    border-radius: 20px;

  }

  .alert-head {
    margin-top: 26px;
    padding-bottom: 6px;
    text-align: center;
  }

  .alert-button-group {
    display: flex;
    justify-content: space-evenly;
  }

  .alert-button-role-cancel {
    text-transform: none;
    font-size: 14px;
    font-family: "Lato-Bold";
    height: 40px;
    width: 88%;
    color: white;
    border-radius: var(--product-card-radius);
    --box-shadow: none;
    background: #a4cc65 !important;
    margin-top: 18px;
  }

  .alert-button-role-confirm {
    text-transform: none;
    font-size: 14px;
    font-family: "Lato-Bold";
    height: 40px;
    width: 88%;
    color: #a4cc66;
    border: 2px solid #a4cc66;
    border-radius: var(--product-card-radius);
    margin-top: 14px;
  }

  .alert-button-inner.sc-ion-alert-md {
    justify-content: space-evenly;
  }

  .top-bottom-margin {
    margin-inline: 140px;
  }

}

@media only screen and (max-device-width: 1022px) {
  .desktop-footer {
    display: none;
  }
}

@media only screen and (min-device-width: 1023px) {
  .bottom-margin {
    bottom: 0px !important;
    --padding-bottom: var(--ion-padding, 0px);
    --padding-top: var(--ion-padding, 86px);
    --padding-end: var(--ion-padding, 0px);
    --padding-start: var(--ion-padding, 0px);
  }

  .contactUsModal,
  .my-custom-class,
  .login-alert-class,
  .cartCalculationModal,
  .autoCheckoutModal {
    min-width: var(--container-size-md, 780px);
    max-width: var(--container-size-md, 780px);
    margin-left: auto;
    margin-right: auto;
  }

  .confirmationModal {
    --width: 340px;
    --height: 368px;
    --border-radius: 20px;
  }
  .searchbar-input.sc-ion-searchbar-md {
    padding-inline-start: 16px !important;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: var(--font-size-h3);
    line-height: 0 !important;
    height: 48px;
    box-shadow: none;
  }

  .desktop-shop-div {
    border-bottom: 1px solid #eeeeee;
  }

  .desktop-shop-div:hover {
    background: #eeeeee;
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    top: 13px;
  }

  body {
    background-color: var(--ion-color-light) !important;
  }

  .transparent-desk-header app-header .in-toolbar {
    --background: transparent;
    box-shadow: none;

    .logo-div,
    .menu-text {
      filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(54deg) brightness(103%) contrast(101%);
    }

    .calender_icon,
    .cart-icon {
      filter: invert(200%) sepia(140%) saturate(5%) hue-rotate(24deg) brightness(103%) contrast(501%);
    }
  }

  .header-shadow app-header {
    .toolbar-label {
      box-shadow: 0px -4px 58px rgb(0 0 0 / 7%), 0px -0.893452px 12.9551px rgb(0 0 0 / 4%), 0px -0.266004px 3.85706px rgb(0 0 0 / 3%);
    }

    .in-toolbar {
      --background: white;

      .calender_icon,
      .cart-icon,
      .menu-icon,
      .logo-div,
      .menu-text {
        filter: none;
      }
    }
  }

  .remove-space {
    .has-header {
      min-width: var(--container-size-md, 100%) !important;
      max-width: var(--container-size-md, 100%) !important;
      ;
    }
  }

  .transparent-desk-header {
    .has-header {
      min-width: 100% !important;
      max-width: 100% !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .title-div {
    h1 {
      font-size: 26px;
    }
  }

  .remove-header {
    display: none;
  }

  .otp-input {
    width: 96px !important;
    font-size: 32px !important;
    height: 90px !important;
    border-radius: 14px !important;
  }

  .profile_category {
    .profile-content {
      box-shadow: none !important;
      border: 2px solid rgba(235, 235, 235, 1);
      margin-inline: 0px !important;
      width: 100% !important;
      padding-bottom: 40px !important;
      background: #FAFAFA !important;
  }

    .top-bottom-margin {
      --padding-bottom: var(--ion-padding, 0px);
      --padding-top: var(--ion-padding, 0px);
      --padding-end: var(--ion-padding, 0px);
      --padding-start: var(--ion-padding, 0px);
    }
  }

}