//font and h1 and h2 and para style body styling
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600&display=swap");

// .font-regular{
//     font-size: var(--font-weight-regular);
// }

// .font-medium{
//     font-size: var(--font-weight-medium);
// }

// .font-semi-bold{
//     font-size: var(--font-weight-semi-bold);
// }

h1 {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-semi-bold);
    // margin-top: 0px;
    // margin-bottom: 0px;
}
