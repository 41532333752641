ion-button{
    text-transform: none;
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-medium);
    // min-width: 84px;
    height: 28px;
    --border-radius:var(--product-card-radius);
   &[size = "small"]{
    line-height: 9px;
    // width: var(--small-button-width);
    // height: var(--small-button-height);
   }
}

.large-button{
    height: 40px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-h3);
}

ion-input{
    
    .native-input[disabled].sc-ion-input-md{
        opacity: 1;
    }
}


