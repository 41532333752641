//font weight,margin large, margin medium

.margin-top-lg{
   margin-top: var(--spacing-lg);
}

.margin-top-md{
    margin-top: var(--spacing-md);
}

.margin-top-sm{
    margin-top: var(--spacing-sm);
}

.padding-horizontal-sm{
    padding-left: var(--spacing-sm);
    padding-right: var(--spacing-sm);
}

.margin-vertical-sm{
    margin-top: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
}

.padding-sm{
    padding: var(--spacing-sm);
}

.margin-bottom-md{
    margin-bottom: var(--spacing-md);
}

// .margin-normal-bottom{
//     margin-bottom: 12px;
// }

.margin-lg-vertical{
    margin-top: var(--spacing-lg);
    margin-bottom:var(--spacing-lg) ;
}

.margin-md-horizontal{
    margin-left: var(--spacing-md);
    margin-right:var(--spacing-md) ;
}

.margin-mdsm-top{
    margin-top: var(--spacing-mdsm);
}

.padding-lg-vertical{
    padding-top: var(--spacing-lg);
    padding-bottom:var(--spacing-lg) ;
}

.padding-lg-horizontal{
    padding-left: var(--spacing-normal);
    padding-right:var(--spacing-normal) ;
}

.padding-mdsm-vertical{
    padding-top: var(--spacing-mdsm);
    padding-bottom: var(--spacing-mdsm);
}


.padding-bottom-normal{
    padding-bottom: var(--spacing-normal);
}

.margin-bottom-normal{
    margin-bottom: var(--spacing-normal);
}

.no-top-padding{
    --paddind-top:0px;
}

.no-margin-vertical{
    margin-top: 0px;
    margin-bottom: 0px;
}

.display-flex{
    display: flex;
}


.padding-top-lg{
    padding-top: var(--spacing-lg);
}

.padding-top-md{
    padding-top: var(--spacing-md);
}

.padding-bottom-md{
    padding-bottom: var(--spacing-md);
}



.margin-md-vertical{
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.padding-md-horizontal{
    padding-left: var(--spacing-md);
    padding-right: var(--spacing-md);
}

.padding-md{
    padding: var(--spacing-md);
}

.margin-no-horizontal{
    margin-left: 0px;
    margin-right: 0px;
}


.no-padding-vertical{
    padding-top: 0px;
    padding-bottom: 0px;
}

.padding-sm-vertical{
    padding-top: 8px;
    padding-bottom: 8px;
}

.no-bottom-margin{
    margin-bottom: 0px;
}

.margin-auto{
    margin: auto;
}

.no-padding-horizontal{
    padding-left: 0px;
    padding-right: 0px;
}

