.custom-loading {
    --background: transparent;
  
    ion-backdrop {
      opacity: 0 !important;
      background: rgba(255, 255, 255, 0.9);
    }
  
    .loading-wrapper {
      box-shadow: none;
      flex-direction: column;
  
      .loading-spinner.sc-ion-loading-md+.loading-content.sc-ion-loading-md {
        -webkit-margin-start: 0;
        margin-inline-start: 0;
        font-size: var(--ion-heading-4);
        letter-spacing: var(--ion-letter-spacing-2);
        margin-top: var(--ion-spacing-small);
        color: var(--ion-color-dark);
      }
  
      .loading-spinner {
        // background-image: url(../images/Gif_prasuk.gif);
        background-repeat: no-repeat;
        background-position: center;
        width: 50px;
        height: 50px;
        background-size: 100%;
  
        ion-spinner {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }