.head {
  display: flex;
  justify-content: flex-end;
  .skipRight {
    display: flex;
    align-items: center;
    .skip {
      font-size: var(--font-size-md);
      font-family: "Lato-Bold";
      color: var(--color-white-step-50);
    }
    .arrow {
      height: var(--spacing-sm-md);
      margin-left: 5px;
    }
  }
}

.registerlogo {
  width: 20%;
  margin: 0 0 20px 0;
}

.heading {
  font-size: 1.6rem;
  margin-top: var(--spacing-sm);
  margin-bottom: var(--spacing-zero);
  color: var(--ion-color-medium-contrast);
  line-height: var(--line-md);
}

.subheading {
  margin-top: var(--spacing-normal);
  font-size: var(--font-size-h2);
  color: var(--color-white-step-100);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-md);
}

.colorunderline {
  margin-top: var(--spacing-md);
  display: flex;
  height: var(--spacing-extra-sm);

  .firstline {
    border: 1px solid;
    width: var(--spacing-normal);
    display: inline-block;
    color: var(--ion-color-primary);
    background-color: var(--ion-color-primary);
  }
  .secondline {
    border: 1px solid;
    width: var(--spacing-extra-sm);
    display: inline-block;
    color: var(--ion-color-primary);
    margin: 0px 4px;
    background-color: var(--ion-color-primary);
  }
  .thirdline {
    border: 1px solid;
    width: 43px;
    display: inline-block;
    color: var(--color-green-step-300);
    background-color: var(--color-green-step-300);
  }
}

.errorMessage {
  line-height: 85.5%;
  position: absolute;
  ion-text {
    font-size: 11px;
  }
}
.spacing {
  margin: 25px 0 20px 0;
}
@media (min-width: 475px) {
  .head .skipRight .skip {
    font-size: var(--font-size-lg);
  }
}