// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
@font-face {   
  font-family: 'Lato-Regular';  //This is what we are going to call  
  src: url('../assets/fonts/lato.regular-webfont.woff');
}
@font-face {   
  font-family: 'Lato-Medium';    
  src: url('../assets/fonts/lato.medium-webfont.woff');
}
@font-face {   
  font-family: 'Lato-Black';    
  src: url('../assets/fonts/lato.black-webfont.woff');
}
@font-face {   
  font-family: 'Lato-Bold';   
  src: url('../assets/fonts/lato.bold-webfont.woff');
  }
@font-face {   
  font-family: 'Lato-Semi-bold';   
  src: url('../assets/fonts//lato.semibold-webfont.woff');
  }
@font-face {   
  font-family: 'Lato-Heavy';    
  src: url('../assets/fonts/lato.heavy-webfont.woff');
}

:root {
  /** primary **/
  --ion-color-primary: #9ac652;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #7d9e46;
  --ion-color-primary-tint: #99bc61;
  --ion-color-main:#9ac652;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c; //grey color
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /**white***/
  --color-white-step-50: rgba(255, 255, 255, 1);
  --color-white-step-100: rgba(255, 255, 255, 0.87);

  /*****font-family *****/
  --ion-font-family: "Lato-Regular";

  /*****color*********/
  --color-dark-step-50: rgba(53, 53, 53, 1);
  --color-dark-step-100: rgba(53, 53, 53, 0.87);
  --color-dark-step-200: rgba(53, 53, 53, 0.7);
  --color-dark-step-300: rgba(53, 53, 53, 0.54);
  --color-dark-step-400: rgba(53, 53, 53, 0.37);
  --color-dark-step-500: rgba(53, 53, 53, 0.20);
  --color-dark-title-50: rgb(18, 18, 18);
  --color-dark-step-placeholder: rgb(139,139,139);


  /*****color mallard*********/
  --color-mallard-step-50: rgba(48, 73, 34, 1);
  --color-mallard-step-100: rgba(48, 73, 34, 0.87);
  --color-mallard-step-200: rgba(48, 73, 34, 0.7);
  --color-mallard-step-300: rgba(48, 73, 34, 0.54);
  --color-mallard-step-400: rgba(48, 73, 34, 0.37);

  /*****color redical-red*********/
  --color-redical-red-step-50: rgba(245, 64, 64, 1);

  /** green  ***/
  --color-green-step-50: rgba(128, 187, 50, 1);
  --color-green-step-100: rgba(128, 187, 50, 0.87);
  --color-green-step-150: rgb(255, 181, 43);
  --color-green-step-200: rgb(165, 209, 93);
  --color-green-step-300: rgb(206, 248, 150);
  --color-green-step-400: rgb(207, 222, 181);
  --color-green-step-500: rgb(253, 227, 172);

  /*******smokeWhite******/

  --color-whitesmoke-step-50: rgb(244, 255, 227);
  --color-whitesmoke-step-100: rgb(249, 255, 239);
  --color-whitesmoke-step-150: rgb(240, 245, 244);
  --color-whitesmoke-step-200: rgb(248, 250, 224);
  --color-whitesmoke-step-250: rgb(248, 250, 244);
  --color-whitesmoke-step-300: rgb(233, 239, 238);
  --color-whitesmoke-step-350: rgb(231, 234, 237);
  --color-whitesmoke-step-400: rgb(246, 248, 242);
  --color-whitesmoke-step-450: rgb(213, 213, 213);
  --color-whitesmoke-step-500: rgb(199, 199, 199);
  --color-whitesmoke-step-550:rgb(226,234,232);
  --color-whitesmoke-step-600: rgb(112, 112, 112);
  --color-whitesmoke-step-700: rgb(147, 147, 147);
  --color-whitesmoke-step-800: rgb(255, 240, 208);
  --color-whitesmoke-step-900: rgb(239, 240, 236);
  --color-whitesmoke-step: rgba(255, 220, 220,1);


  /*********brown*********/
  --color-brown-step-50: rgba(48, 73, 34, 1);
  --color-brown-step-100: rgba(48, 73, 34, 0.87);
  --color-brown-step-200: rgba(48, 73, 34, 0.7);

  /********spacing**********/
  --spacing-max-lg: 52px;
  --spacing-extra-lg: 48px;
  --spacing-lg: 32px; 
  --spacing-lg-md: 28px;
  --spacing-md-lg: 26px;
  --spacing-md: 24px;
  --spacing-normal-md: 22px;
  --spacing-normal: 20px;
  --spacing-md-normal: 14px;
  --spacing-mdsm: 12px;
  --spacing-sm-md: 11px;
  --spacing-sm: 8px;
  --spacing-extra-sm: 4px;
  --spacing-zero: 0px;

  /********font weight******/
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  /*********font size********/
  --font-size-max:32px;
  --font-size-extra-lg: 28px;
  --font-size-lg: 24px;
  --font-size-md-lg: 22px;
  --font-size-h1: 20px;
  --font-size-md: 18px;
  --font-size-h2: 16px;
  --font-size-h3: 14px;
  --font-size-h4: 12px;
  --font-size-h5: 10px;
  --font-size-cart:15px;
  /*********line height*********/

  --line-height-lg: 24px;
  --line-height-md: 12px;
  --line-height-small: 10px;
  --line-height-extra-small: 2px;
  --line-md: 125%;

  /**********radius**************/
  
  --border-radius-lg: 8px;
  --border-radius-md: 6px;
  --input-radius: 4px;
  --product-card-radius: 2px;
}